import { createSlice } from "@reduxjs/toolkit";

export interface PageState {
  isFetching: boolean;
}

export const initialState: PageState = {
  isFetching: false,
};

export const pageSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    initialize: (state) => {
      state.isFetching = false;
    },
    onLeaveFetching: (state) => {
      state.isFetching = true;
    },
    onLeaveSuccess: (state) =>{
      state.isFetching = false;
    },
    onLeaveFail: (state) => {
      state.isFetching = false;
    }
  }
});

export const {
  initialize,
  onLeaveFetching,
  onLeaveSuccess,
  onLeaveFail
} = pageSlice.actions;

export const confirmReducer = pageSlice.reducer;
